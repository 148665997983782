import React, { Component } from "react";
import { Index } from "elasticlunr";
import { Link } from "gatsby";
import { Dropdown } from "react-bootstrap";

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: ``,
      results: []
    };
  }

  render() {
    return (
      <Dropdown className="m-0 p-0">
        <Dropdown.Toggle
          as="input"
          type="text"
          data-toggle="dropdown"
          className="form-control m-0"
          placeholder="Search"
          aria-label="search"
          value={this.state.query}
          onChange={this.search}
        />
        <Dropdown.Menu className="mt-1">
          {this.state.results.map(page => (
            <Dropdown.Item key={page.id} >
              <Link className="text-dark" to={page.slug}>{page.title}</Link>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
      Index.load(this.props.searchIndex);

  search = evt => {
    const query = evt.target.value;
    this.index = this.getOrCreateIndex();
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, {})
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref))
    });
  };
}