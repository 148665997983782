import React from "react";
import { graphql, StaticQuery } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style/styles.css";
import { NavBar } from "./navBar";

const Header = (props) => (
  <StaticQuery
    query={graphql`
      query headerTitleQuery {
        site {
          siteMetadata {
            headerTitle
            logo {
              link
              image
            }
          }
        }
      }
    `}
    render={data => {
      const { site: {siteMetadata: { headerTitle }}} = data;

      return (
        <NavBar
          brand={
            <a className="text-dark" style={{ fontFamily: "DM Sans", fontWeight: 700 }} href="/">
              {headerTitle}
            </a>
          }
          search={props.search}
          openInTab={props.openInTab}
        />
      );
    }}
  />
);

export default Header;
