import log from "loglevel";
import prefix from "loglevel-plugin-prefix";
import VERSION from "../../assets/text/VERSION.json";
import { v4 as uuidv4 } from "uuid";
//import remote from "./remote"


export const CreateNewSession = () => {
  return uuidv4();
};

const getScreenSize = () => {
  if (typeof window === "undefined"){
    return "100x100"
  }
  return `${window.innerWidth}x${window.innerHeight}`;
}

const session = CreateNewSession();

const screenSize = getScreenSize()

const prefixDefaults = {
  template: `%t - %l - ${VERSION.branch} - ${session} - ${screenSize} - `,
  levelFormatter: function(level) {
    return level.toUpperCase();
  },
  timestampFormatter(date) {
    return date.toISOString();
  },
  format: undefined
};

const remoteFormat = log => {
  let { message, level, timestamp } = log;

  return {
    message,
    level: level.label,
    branch: VERSION.branch,
    session: session,
    screenSize: screenSize,
    timestamp
  };
};

const remoteDefaults = {
  url: "https://logstash.procedural.build",
  method: "POST",
  headers: { "Content-Type": "application/json" },
  token: "",
  onUnauthorized: failedToken => {
  },
  timeout: 0,
  interval: 100,
  level: "trace",
  backoff: {
    multiplier: 2,
    jitter: 0.1,
    limit: 30000
  },
  capacity: 1,
  stacktrace: {
    levels: ["trace", "warn", "error"],
    depth: 3,
    excess: 0
  },
  timestamp: () => new Date().toISOString(),
  format: remoteFormat
};

prefix.reg(log);
log.enableAll();
prefix.apply(log, prefixDefaults);

/*if (VERSION.branch !== "dev") {
  remote.apply(log, remoteDefaults);
}*/

export const logger = log;