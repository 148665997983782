import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Search from "./search";
import { logger } from "../logging/logger";

export const NavBar = (props) => {
  let { search = true, openInTab = true } = props;

  if (!search) {
    return (
      <Nav siteSearchIndex={null} brand={props.brand} search={false} />
    );
  }
  return (
    <StaticQuery
      query={graphql`
      query SearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `}
      render={data => (
        <Nav siteSearchIndex={data.siteSearchIndex} brand={props.brand} search={true} openInTab={openInTab}/>
      )}
    />

  );
};


const Nav = props => {
  let { search, brand, siteSearchIndex, openInTab } = props;

  return (
    <div className="navbar-area" style={{ fontFamily: "Sans Serif" }}>
      <nav
        className={`navbar navbar-expand-lg navbar-light bg-light navbar-static-top`}
        role="navigation"
      >
        <div className="container-fluid m-0 p-0 row">
          <div className="navbar-brand col-3 col-sm-9 m-0 p-0">{brand}</div>

          {search ? <div className="col-6 col-sm-2"><Search searchIndex={siteSearchIndex.index} /></div> :
            <div className="col-6 col-sm-2" />}
          <a
            className="btn btn-outline-secondary text-dark col-3 col-sm-1"
            href="https://compute.procedural.build/accounts/profile/"
            target={openInTab? "_blank": ""}
            rel={openInTab? "noopener noreferrer": ""}
            onClick={() => {logger.info("Click Log In - Header"); return true}}
          >
            Login
          </a>
        </div>
      </nav>
    </div>
  );
};